<template>
  <BCard body-class="p-3">
    <BOverlay
      :show="isLoadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BRow class="items-center justify-between d-flex mb-2">
        <BCol>
          <div class="flex items-center">
            <BButton
              variant="primary"
              size="sm"
              class="mr-1"
              @click="$router.go(-1)"
            >
              <feather-icon
                size="1x"
                icon="ChevronLeftIcon"
              />
            </BButton>
            <div class="text-black font-bold m-0 text-2xl">
              Rincian Penarikan Saldo
            </div>
          </div>
        </BCol>
        <BCol
          v-if="dataWithdrawal !== null"
          class="text-end"
        >
          <span :class="statusWithdrawalClass(dataWithdrawal.withdrawal_status)">{{
            statusWithdrawal(dataWithdrawal.withdrawal_status)
          }}</span>
          <span v-if="topupMenu">
            <BButton
              id="private-btn"
              class="ml-1"
              variant="primary"
              size="sm"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/more.svg"
                alt=""
              >
            </BButton>
            <b-popover
              target="private-btn"
              triggers="hover"
              placement="bottom"
              custom-class="w-64"
            >
              <button
                class="btn"
                @click="setActionModal('Top Up Saldo')"
              >
                Top Up Saldo
              </button>
              <button
                class="btn"
                @click="setActionModal('Pengurangan Saldo')"
              >
                Pengurangan Saldo
              </button>
              <button
                class="btn"
                :disabled="transferSaldo"
                @click="setActionModal('Transfer Saldo')"
              >
                Transfer Saldo
              </button>
              <button
                class="btn"
                @click="setActionModal('Log Riwayat')"
              >
                Log Riwayat
              </button>
            </b-popover>
          </span>
        </BCol>
      </BRow>
      <div v-if="dataWithdrawal !== null">
        <BRow class="my-2">
          <BCol
            lg="6"
            class="my-2"
          >
            <card-detail-withdrawal
              :data-withdrawal="dataWithdrawal"
              :product-of="productOf"
              :partner-badge="partnerBadge"
            />
          </BCol>
          <BCol
            lg="6"
            class="my-2"
          >
            <card-saldo-user :balance-analitycs="dataWithdrawal.balance_analitycs" />
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol class="my-2">
            <card-summary-info
              :partner-name="dataWithdrawal.partner_name"
              :partner-notes="dataWithdrawal.partner_notes"
              :date-updated="dataWithdrawal.partner_notes_last_update"
              :partner-verified="dataWithdrawal.partner_is_verified"
              :partner-id="partnerId"
              :summary-information="dataWithdrawal.summary_information"
              @refresh-withdrawal="getDetailWithdrawal()"
            />
          </BCol>
          <BCol class="my-2">
            <card-similarity-account :similar-accounts="dataWithdrawal.similar_accounts" />
          </BCol>
        </BRow>
        <BRow class="my-2">
          <BCol class="my-2">
            <card-logs :logs-changed="logsChanged" />
          </BCol>
        </BRow>
        <BRow v-if="dataWithdrawal !== null">
          <BCol
            :lg="
              dataWithdrawal.withdrawal_status === 'requested' ||
                dataWithdrawal.withdrawal_status === 'on_review'
                ? 3
                : 4
            "
          >
            <BCard class="balance">
              <div class="mb-1 text-black">
                Saldo Terkini
              </div>
              <span class="font-semibold text-2xl text-[#F95031]">
                {{ IDR(dataWithdrawal.current_balance) }}
              </span>
            </BCard>
          </BCol>
          <BCol
            :lg="
              dataWithdrawal.withdrawal_status === 'requested' ||
                dataWithdrawal.withdrawal_status === 'on_review'
                ? 3
                : 4
            "
          >
            <BCard class="balance">
              <div class="mb-1 text-black">
                Request Penarikan
              </div>
              <span
                style="color:#34A770"
                class="font-semibold text-2xl"
              >
                {{ IDR(dataWithdrawal.withdrawal_nominal) }}
              </span>
            </BCard>
          </BCol>
          <BCol
            :lg="
              dataWithdrawal.withdrawal_status === 'requested' ||
                dataWithdrawal.withdrawal_status === 'on_review'
                ? 3
                : 4
            "
          >
            <BCard class="balance">
              <div class="mb-1 text-black">
                Sisa Jika Disetujui
              </div>
              <span
                style="color:#08A0F7"
                class="font-semibold text-2xl"
              >
                {{ IDR(dataWithdrawal.after_approval_balance) }}
              </span>
            </BCard>
          </BCol>
          <BCol
            v-if="
              dataWithdrawal.withdrawal_status === 'requested' ||
                dataWithdrawal.withdrawal_status === 'on_review'
            "
            lg="3"
            class="flex flex-column justify-center"
          >
            <div class="flex">
              <BButton
                id="popoverStatus"
                variant="outline-primary"
              >
                {{ filters.label }}
              </BButton>
              <BPopover
                id="popover-filter-type"
                target="popoverStatus"
                triggers="hover focus"
                placement="bottom"
              >
                <div
                  v-for="(data, index) in chooseAction"
                  :key="index + 1"
                >
                  <BButton
                    v-model="data.value"
                    variant="flat-dark"
                    @click="setActions(data)"
                  >
                    {{ data.label }}
                  </BButton>
                </div>
              </BPopover>
              <div class="ml-1">
                <BButton
                  variant="primary"
                  @click="handleActions"
                >
                  Terapkan
                </BButton>
              </div>
            </div>
          </BCol>
        </BRow>
      </div>
    </BOverlay>
    <div
      v-if="dataWithdrawal !== null"
      class="mt-1"
    >
      <div class="flex">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/notes.svg"
          alt="Komerce"
        >
        <p class="text-black font-bold text-start m-0">
          Catatan :
        </p>
      </div>
      <div class="mt-1">
        {{ dataWithdrawal.notes }}
      </div>
    </div>
    <BOverlay
      :show="isLoadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        class="mt-2"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(created_at)="value">
          {{ DAY_MONTH_YEAR(value.item.created_at) }}
          <br>
          {{ TIME(value.item.created_at) }}
        </template>
        <template #cell(saldo)="value">
          <span class="font-semibold">{{ IDR(value.item.saldo) }}</span>
        </template>
        <template #cell(nominal)="value">
          <span :class="classNominal(value.item.type)">{{
            labelNominal(value.item)
          }}</span>
        </template>
        <template #cell(type)="value">
          <span class="font-medium">{{
            labelTransactionType(value.item)
          }}</span>
          <div
            v-if="setNotes(value.item)"
            class="text-muted"
            v.b-tooltip.hover
            :title="value.item.notes"
          >
            ({{ splitNotes(value.item) }})
          </div>

          <div class="flex items-center">
            <img
              v-if="value.item.shipping_logo !== ''"
              :src="value.item.shipping_logo"
              class="mr-[5px]"
              style="width:50px"
              alt="Komerce"
            >
            <img
              v-if="value.item.cnote !== ''"
              v-b-tooltip.hover="`Copy nomor resi ${value.item.cnote}`"
              src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
              alt="Komerce"
              class="cursor-pointer"
              @click="toastCopyResi(value.item.cnote)"
            >
          </div>
        </template>
        <template #cell(action)="value">
          <span
            v-if="
              value.item.description === 'orderku_done' ||
                value.item.description === 'orderku_cancel' ||
                value.item.description === 'orderku_retur' ||
                value.item.description === 'orderku_ongkir'
            "
            style="color: #08A0F7"
            class="font-semibold cursor-pointer"
            @click="onRowSelected(value.item)"
          >
            Lihat Detail
          </span>
          <span v-else>-</span>
        </template>
      </BTable>
      <BRow v-if="size(items) !== 0">
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div
            class="
              bg-light
              d-flex
              justify-content-center
              align-items-center
              p-50
              rounded
            "
          >
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>
    <BRow
      v-if="dataWithdrawal !== null"
      class="justify-between mt-2"
    >
      <h3 class="text-black font-semibold text-3xl ml-1">
        Saldo Awal :
      </h3>
      <h3
        style="color: #F95031"
        class="text-black font-semibold text-3xl mr-5"
      >
        {{ IDR(dataWithdrawal.beginning_balance) }}
      </h3>
    </BRow>

    <BModal
      id="modal-transfer-now"
      ref="modal-transfer-now"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
      size="md"
      no-stacking
    >
      <ModalTransferNow :get-detail-withdrawal="getDetailWithdrawal" />
    </BModal>

    <BModal
      id="modal-review"
      ref="modal-review"
      no-stacking
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      hide-header
    >
      <ModalReview :get-detail-withdrawal="getDetailWithdrawal" />
    </BModal>

    <BModal
      id="modal-reject"
      ref="modal-reject"
      no-stacking
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      hide-header
    >
      <ModalReject :get-detail-withdrawal="getDetailWithdrawal" />
    </BModal>

    <ModalPrivate :modal-value="modalValue" />

    <ModalPin
      :modal-value="modalValue"
      :get-detail-withdrawal="getDetailWithdrawal"
    />
  </BCard>
</template>

<script>
import { komshipAxiosIns, axiosIns, newAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { mapState } from 'vuex'
import { listDetailWithdrawal, chooseAction } from './config'
import ModalTransferNow from './ModalTransferNow.vue'
import ModalReview from './ModalReview.vue'
import ModalReject from './ModalReject.vue'
import ModalPrivate from './ModalPrivate.vue'
import ModalPin from './ModalPIN.vue'
import CardDetailWithdrawal from './component/CardDetailWithdrawal.vue'
import CardSaldoUser from './component/CardSaldoUser.vue'
import CardSummaryInfo from './component/CardSummaryInfo.vue'
import CardSimilarityAccount from './component/CardSimilarityAccount.vue'
import CardLogs from './component/CardLogs.vue'

export default {
  components: {
    ModalTransferNow,
    ModalReview,
    ModalReject,
    ModalPrivate,
    ModalPin,
    CardDetailWithdrawal,
    CardSaldoUser,
    CardSummaryInfo,
    CardSimilarityAccount,
    CardLogs,
  },
  data() {
    return {
      isLoadingTable: false,
      isLoading: false,
      dataWithdrawal: null,
      filters: {
        label: 'Pilih Tindakan',
        value: '',
      },
      chooseAction,
      items: [],
      fields: listDetailWithdrawal,
      idWithdrawal: this.$route.params.slug,
      DAY_MONTH_YEAR,
      TIME,
      IDR,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [20, 50, 100, 200, 400],
      totalPerPage: 20,
      isEmpty,
      size,
      modalValue: {
        titleModal: '',
        subTitle: '',
        type: '',
        placeholderNotes: '',
        withdrawalId: this.$route.params.slug,
        nominal: 0,
        email: '',
        notes: '',
        listEmail: [],
        listLogTransactions: [],
        emailRequired: false,
      },
      transferSaldo: false,
      topupMenu: false,
      productOf: [],

      logsChanged: {},
      partnerBadge: {},
      partnerId: 0,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getDetailWithdrawal()
      },
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('menuAdmin', ['listMenu']),
  },
  mounted() {
    this.getDetailWithdrawal()
  },
  methods: {
    async getLogsChanged(id) {
      const url = `/auth/api/v1/admin/user/${id}/log-account-change`
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.logsChanged = data
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal update data logs' })
        })
    },
    async getPartnerBadge(id) {
      const url = `/komship/api/v1/admin/withdrawal/lib/partner-badge/${id}`
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.partnerBadge = data
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal mendapatkan level user' })
        })
    },
    async getDetailWithdrawal() {
      this.isLoading = true
      const url = `/auth/api/v1/admin/withdrawal/detail/${this.idWithdrawal}`
      await newAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.dataWithdrawal = data
          this.isLoading = false
          this.isLoadingTable = true
          this.partnerId = data.partner_id

          await this.getEmail(data.summary_information.partner_email)
          await this.getLogsChanged(data.partner_user_id)
          await this.getPartnerBadge(this.partnerId)

          const params = {
            total_per_page: this.totalPerPage,
            page: this.currentPage,
            withdrawal_id: this.idWithdrawal,
          }
          const urlList = `/v2/admin/withdrawal/detail/list/${data.partner_user_id}`
          await komshipAxiosIns
            .get(urlList, { params })
            .then(response => {
              this.items = response.data.data.data
              this.isLoadingTable = false
              this.totalRows = response.data.data.total
              this.getMenuAdmin()
            })
            .catch(err => {
              this.$toast(
                {
                  component: ToastificationContentVue,
                  props: {
                    title: 'Failure',
                    icon: 'AlertCircleIcon',
                    text: err.response.data.message,
                    variant: 'danger',
                  },
                },
                1000,
              )
              this.isLoadingTable = false
            })
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            1000,
          )
          this.isLoading = false
        })
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getDetailWithdrawal()
    },
    setActions(data) {
      this.filters = data
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    handleActions() {
      if (this.filters.value === 'transfer_now') { return this.$refs['modal-transfer-now'].show() }
      if (this.filters.value === 'review') { return this.$refs['modal-review'].show() }
      if (this.filters.value === 'tolak') { return this.$refs['modal-reject'].show() }
      if (this.filters.value === 'transfer_manual') {
        return this.$router.push({
          name: 'cod-penarikan-upload-bukti-transfer',
          params: { slug: this.$route.params.slug },
        })
      }
      return ''
    },
    statusWithdrawal(status) {
      if (status === 'rejected') return 'Ditolak'
      if (status === 'requested') return 'Request Penarikan'
      if (status === 'completed') return 'Disetujui'
      if (status === 'on_review') return 'Sedang Direview'
      if (status === 'in_process') return 'Diproses'
      return ''
    },
    statusWithdrawalClass(status) {
      if (status === 'rejected') return 'rejected'
      if (status === 'requested') return 'requested'
      if (status === 'on_review') return 'on_review'
      if (status === 'completed') return 'completed'
      if (status === 'in_process') return 'in_process'
      return ''
    },
    labelTransactionType(value) {
      const { description, order_payment_method } = value
      if (description === 'orderku_ongkir') return 'Orderan Non-COD (Ongkir)'
      if (description === 'orderku_cancel') return 'Orderan Non-COD (Cancel)'
      if (description === 'withdrawal') return 'Penarikan Saldo'
      if (description === 'shopping') return 'Belanja Keperluan Talent'
      if (description === 'shopping') return 'Belanja Keperluan Talent'
      if (description === 'topup') return 'Top Up Saldo'
      if (description === 'balance_reduction' || description === 'transfer') { return 'Pengurangan Saldo' }
      if (description === 'payment_feature') return 'Berlangganan Fitur'
      if (description === 'payment_fulfillment_fee') return 'Biaya Fulfillment'
      if (description === 'cancel_fulfillment_fee') { return 'Pembatalan Fulfillment' }
      if (description === 'outbound_fee') return 'Penarikan Barang'
      if (description === 'outbound_cancel') { return 'Pembatalan Penarikan Barang' }
      // eslint-disable-next-line camelcase
      if (description === 'orderku_retur' && order_payment_method === 'COD') { return 'Orderan COD (Retur)' }
      // eslint-disable-next-line camelcase
      if (description === 'orderku_done' && order_payment_method === 'COD') { return 'Orderan COD (Selesai)' }
      // eslint-disable-next-line camelcase
      if (
        description === 'orderku_retur'
        // eslint-disable-next-line camelcase
        && order_payment_method === 'BANK TRANSFER'
      ) { return 'Orderan Non-COD (Retur)' }
      // eslint-disable-next-line camelcase
      if (
        description === 'orderku_done'
        // eslint-disable-next-line camelcase
        && order_payment_method === 'BANK TRANSFER'
      ) { return 'Orderan Non-COD (Selesai)' }
      if (description === 'orderku_done') return 'Orderan Non-COD (Selesai)'
      if (description === 'topup_komcard') return 'Top Up Komcards'
      if (description === 'refund_komcard') return 'Tarik Saldo Komcards'
      return ''
    },
    labelNominal(item) {
      const { type, nominal } = item
      if (type === 'credit') return `+ ${IDR(nominal)}`
      if (type === 'debit') return `- ${IDR(nominal)}`
      return ''
    },
    classNominal(type) {
      if (type === 'credit') return 'typeGreen'
      if (type === 'debit') return 'typeRed'
      return ''
    },
    onRowSelected(data) {
      const { description, order_id } = data
      if (
        description === 'orderku_ongkir'
        || description === 'orderku_done'
        || description === 'orderku_done'
        || description === 'orderku_retur'
      ) {
        const url = this.$router.resolve({
          // eslint-disable-next-line camelcase
          path: `/tiket/detail/order/${order_id}`,
        })
        window.open(url.href, '_blank')
      }
    },
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: `${number} berhasil disalin`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        1000,
      )
    },
    handleColorBank(number) {
      if (number < 0) {
        return 'color: #E31A1A'
      }
      return 'color: #34A770'
    },
    getMenuAdmin() {
      this.topupMenu = this.listMenu.map(item => item.name).includes('Topup')
      if (
        this.dataWithdrawal.information_summary.same_bank_account_by_name
          .length === 0
        && this.dataWithdrawal.information_summary.same_bank_account_by_no
          .length === 0
      ) {
        this.transferSaldo = true
      }
    },
    setActionModal(value) {
      this.modalValue.titleModal = value
      this.modalValue.subTitle = value
      if (this.modalValue.titleModal === 'Top Up Saldo') {
        this.modalValue.type = 'TopupManual'
        this.modalValue.placeholderNotes = 'Top up Rp xx.xxx untuk keperluan ganti rugi paket hilang/rusak'
        this.$bvModal.show('modal-private-action')
      } else if (this.modalValue.titleModal === 'Pengurangan Saldo') {
        this.modalValue.type = 'BalanceReduction'
        this.modalValue.placeholderNotes = 'Pengurangan saldo sebesar Rp xx.xxx untuk transaksi xyz'
        this.$bvModal.show('modal-private-action')
      } else if (
        this.modalValue.titleModal === 'Transfer Saldo'
        && !this.transferSaldo
      ) {
        this.modalValue.subTitle = 'Pengurangan Saldo'
        this.modalValue.type = 'TransferSaldo'
        this.modalValue.emailRequired = true
        this.modalValue.placeholderNotes = 'Pemindahan dana Rp xx.xxx dari putri1@gmail.com ke putri2@gmail.com'
        this.modalValue.listEmail = this.dataWithdrawal.information_summary.same_bank_account_by_name.concat(
          this.dataWithdrawal.information_summary.same_bank_account_by_no,
        )
        this.$bvModal.show('modal-private-action')
      } else {
        const endpoint = `/v1/user/admin/log-transactions/${this.dataWithdrawal.partner_user_id}`
        axiosIns
          .get(endpoint)
          .then(response => {
            const { data } = response.data
            this.modalValue.listLogTransactions = data
            this.$bvModal.show('modal-private-action')
          })
          .catch(err => {
            this.errorPin = true
            this.isLoading = false
            this.disableBtn = true
          })
      }
      this.modalValue.current_balance = this.dataWithdrawal.current_balance
    },
    splitNotes(item) {
      if (
        item.notes.length > 25
        && item.description !== 'topup_komcard'
        && item.description !== 'refund_komcard'
      ) {
        return `${item.notes.slice(0, 25)}...`
      }
      if (String(item.notes).includes('refund balance failed create card')) {
        return 'Kuota kartu habis'
      }
      if (item.notes.includes('initiate create card')) {
        return 'Kuota kartu habis'
      }
      return item.notes
    },
    setNotes(item) {
      if (
        (item.description === 'topup'
          || item.description === 'balance_reduction'
          || item.description === 'transfer'
          || item.description === 'payment_feature'
          || item.description === 'topup_komcard'
          || item.description === 'refund_komcard')
        && item.notes !== null
      ) {
        return true
      }
      return false
    },
    getEmail(values) {
      const url = '/komclass/api/v1/event/option/email'
      newAxiosIns
        .get(url, {
          params: { email: values },
        })
        .then(res => {
          const { data } = res.data
          const [detail] = data
          this.productOf = [
            detail.is_komship === 1 ? 'https://storage.googleapis.com/komerce/icon/komship-with-fill.svg' : '',
            detail.is_komtim === 1 ? 'https://storage.googleapis.com/komerce/icon/komtim-with-fill.svg' : '',
            detail.is_kompack === 1 ? 'https://storage.googleapis.com/komerce/icon/kompack-with-fill.svg' : '',
            detail.is_komcard === 1 ? 'https://storage.googleapis.com/komerce/icon/komcards-with-fill.svg' : '',
            detail.is_komplace === 1 ? 'https://storage.googleapis.com/komerce/icon/komplace-with-fill.svg' : '',
            detail.is_pendamping_umkm === 1 ? 'https://storage.googleapis.com/komerce/icon/pumkm-with-fill.svg' : '',
            detail.is_affiliate === 1 ? 'https://storage.googleapis.com/komerce/icon/affiliate-with-fill.svg' : '',
            detail.is_komform === 1 ? 'https://storage.googleapis.com/komerce/icon/komform-with-fill.svg' : '',
          ].filter(Boolean)
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          this.$toast_error({ message: 'get data product option failed' })
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import './Pencairan.scss';
</style>
