<template>
  <div class="bg-[#FFFFFF]">
    <div class="w-100 bg-[#F8F8F8] border border-2 text-black p-1 rounded-t-xl font-semibold">
      Detail Penarikan Saldo
    </div>
    <div class="p-1 border h-[33rem] border-2 rounded-b-xl">
      <BRow class="my-2 align-self-center">
        <BCol
          cols="5"
          class="align-self-center"
        >
          Waktu Request
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold"
        >
          : {{ DAY_MONTH_YEAR(dataWithdrawal.withdrawal_date) }} ({{
            TIME(dataWithdrawal.withdrawal_date)
          }}
          WIB)
        </BCol>
      </BRow>
      <BRow class="my-2 align-self-center">
        <BCol
          cols="5"
          class="align-self-center"
        >
          Nama
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold d-flex"
        >
          <span>: {{ dataWithdrawal.bank_account_name }}</span>
        </BCol>
      </BRow>
      <BRow class="my-2 align-self-center">
        <BCol
          cols="5"
          class="align-self-center"
        >
          No HP
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold"
        >
          : {{ dataWithdrawal.partner_phone }}
        </BCol>
      </BRow>
      <BRow class="my-2 align-self-center">
        <BCol
          cols="5"
          class="align-self-center"
        >
          Nama Bank
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold"
        >
          : {{ dataWithdrawal.bank_name }}
        </BCol>
      </BRow>
      <BRow class="my-2 align-self-center">
        <BCol
          cols="5"
          class="align-self-center"
        >
          No Rekening
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold d-md-flex"
        >
          <span>: {{ dataWithdrawal.bank_account_no }}</span>
          <div class="d-flex">
            <BBadge
              v-if="dataWithdrawal.bank_account_first_withdraw"
              variant="success"
              class="ml-1"
              style="font-weight: 400; line-height: normal"
            >
              New
            </BBadge>
            <img
              v-b-tooltip.hover="
                `Copy nomor rekening ${dataWithdrawal.bank_account_no}`
              "
              src="https://storage.googleapis.com/komerce/assets/copy.svg"
              alt="copy"
              class="cursor-pointer ml-1"
              @click="toastCopy(dataWithdrawal.bank_account_no)"
            >
          </div>
        </BCol>
      </BRow>
      <BRow class="my-2">
        <BCol
          cols="5"
          class="align-self-center"
        >
          Pengguna Produk
        </BCol>
        <BCol
          cols="7"
          class="d-flex gap-[10px] text-black font-semibold align-items-center"
        >
          :
          <div
            v-for="item in productOf"
            :key="item"
            class="border rounded-full"
          >
            <img
              :src="item"
              class="w-10"
            >
          </div>
        </BCol>
      </BRow>
      <BRow class="my-2 align-self-center">
        <BCol
          cols="5"
          class="align-self-center"
        >
          Level User
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold align-self-center d-flex align-self-center"
        >
          :
          <img
            v-if="partnerBadge.level_name !== 'Reguler'"
            class="ml-1 w-8"
            :src="partnerBadge.image_url"
            :alt="partnerBadge.level_name"
          >
          <span class="align-self-center ml-[5px]">{{ partnerBadge.level_name }}</span>
        </BCol>
      </BRow>
      <BRow class="my-2">
        <BCol
          cols="5"
          class="align-self-center"
        >
          Riwayat Risk Review
        </BCol>
        <BCol
          cols="7"
          class="text-black font-semibold d-flex align-items-center text-sm"
        >
          :
          <div
            :class="getRiskReview(dataWithdrawal.risk_review_history)"
            class="p-[5px] rounded-xl mx-1 px-1 d-flex"
          >
            <div class="align-self-center mr-[5px]">
              {{ riskReview }}
              <span v-if="riskReview !== 'None'">{{ DATE_NUMBER_SLASH(dataWithdrawal.risk_review_history[dataWithdrawal.risk_review_history.length - 1].review_date) }}</span>
            </div>
            <div
              v-if="riskReview !== 'None'"
              id="riwayat-risk-review"
              class="k-info-circle align-items-center text-xl cursor-pointer"
            />
            <b-tooltip
              v-if="riskReview !== 'None'"
              target="riwayat-risk-review"
              triggers="hover"
              placement="top"
            >
              <div class="font-semibold text-start mb-1">
                Riwayat Risk Review
              </div>
              <div
                v-for="(item, idx) in dataWithdrawal.risk_review_history"
                :key="idx"
                class="text-start d-flex"
              >
                <div>{{ idx + 1 }}.</div>
                <div class="mx-[5px]">
                  {{ convertRiskReview(item.status) }}
                </div>
                <div>({{ DATE_NUMBER_SLASH(item.review_date) }})</div>
              </div>
            </b-tooltip>
          </div>
        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { DAY_MONTH_YEAR, DATE_NUMBER_SLASH, TIME } from '@/libs/filterDate'

export default {
  props: {
    dataWithdrawal: {
      type: Object,
      default: () => {},
      required: true,
    },
    productOf: {
      type: Array,
      default: () => [],
      required: true,
    },
    partnerBadge: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      DATE_NUMBER_SLASH,
      TIME,
      level: 0,
      riskReview: 'None',
    }
  },
  methods: {
    toastCopy(number) {
      navigator.clipboard.writeText(number)
      this.$toast_success({ message: `${number} berhasil disalin` })
    },
    getRiskReview(risk) {
      let result
      const status = risk === null ? null : risk[risk.length - 1].status

      if (status === null) {
        result = 'bg-[#DFF3FF] text-[#08A0F7]'
      } else if (status === 1) {
        this.riskReview = 'Fake'
        result = 'bg-[#828282] text-[#333333]'
      } else if (status === 2) {
        this.riskReview = 'Hold'
        result = 'bg-[#FCBEBE] text-[#E31A1A]'
      } else if (status === 3) {
        this.riskReview = 'Unhold'
        result = 'bg-[#FCEBBE] text-[#FBA63C]'
      }
      return result
    },
    convertRiskReview(status) {
      let result
      if (status === 3) {
        result = 'Unhold'
      } else if (status === 2) {
        result = 'Hold'
      } else if (status === 1) {
        result = 'Fake'
      }
      return result
    },
  },
}
</script>
